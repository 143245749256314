<template>
    <div>
        <div align="center">
            <h4>Segnala problemi sulla pratica</h4>
            <hr>
        </div>

        <div class="row justify-center">

            <div class="col-12 col-md-5 q-px-md">
                <q-list  separator>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Cliente:</q-item-label>
                            <q-item-label caption>{{RecordSelezionato.name}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Stato della pratica:</q-item-label>
                            <q-item-label caption>{{getStatoPratica}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Unità operativa richiedente:</q-item-label>
                            <q-item-label caption>{{RecordSelezionato.unita_operativa}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Persona operativa:</q-item-label>
                            <q-item-label caption>{{RecordSelezionato.operatore}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Numero pratica:</q-item-label>
                            <q-item-label caption>{{RecordSelezionato.numero}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Numero interno:</q-item-label>
                            <q-item-label caption>{{RecordSelezionato.numero_interno}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Data di decorrenza richiesta:</q-item-label>
                            <q-item-label caption>{{getDataDecorrenzaPratica}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Note:</q-item-label>
                            <q-item-label caption>{{annotazione}}</q-item-label>
                        </q-item-section>
                    </q-item>
                </q-list>
                <br><br>
            </div>

            <div class="col-12 col-md-5 q-mr-sm">
                <h5 class="q-mt-sm">Descrivi i problemi riscontrati</h5>

                <q-input
                    outlined
                    square
                    v-model="descrizione_errori"
                    filled
                    name="segnala_problema"
                    type="textarea"
                />
            </div>

            <div class="col-12 col-md-12">
                <hr>
            </div>

            <div class="row col-12 col-md-12 justify-center">

                <div class="col-12 col-md-6" align="center">
                    <QQButton label="TORNA AL MENU"
                        color="blue-grey"
                        icon="undo"
                        size="md"
                        icon_align="left"
                        @click.native="onIndietroClicked()"
                    />
                </div>

                <div class="col-12 col-md-6" align="center">
                    <QQButton label="INVIA SEGNALAZIONE"
                        color="blue-grey"
                        icon="mdi-email-edit-outline"
                        size="md"
                        icon_align="right"
                        @click.native="onRegistraClicked()"
                    />
                </div>


            </div>

        </div>

    </div>
</template>

<script>
    import { mapFields} from 'vuex-map-fields';
    import { mapState,mapActions } from 'vuex'
    import QQButton from "@/components/QQButton.vue";
    import commonLib from "@/libs/commonLib";

    export default {
        name: "SegnalaProblemi",
        components: {
            QQButton
        },
        data() {
            return {
                descrizione_errori: "",
                annotazione: ""
            }
        },
        computed: {
            ...mapState({
                area: state => state.area,
                titolo_area: state => state.titolo_area,
                formPratica: state => state.gestionePratiche.formPratica
            }),
            ...mapFields("gestionePratiche", {
                RecordSelezionato: "RecordSelezionato",
            }),
            getStatoPratica() {
                if (commonLib.isEmpty(this.formPratica)) return "";
                return this.formPratica.stato_pratica.toUpperCase();
            },
            getDataDecorrenzaPratica() {
                if (commonLib.isEmpty(this.formPratica)) return "";
                return this.formPratica.preventivo.decorrenza;
            },
        /*    getFrazionamentoPratica() {
                if (commonLib.isEmpty(this.formPratica)) return "";

                return this.formPratica.preventivo.frazionamento.toUpperCase();
            } */
        },
        methods: {
            ...mapActions({
                setProblemaSuPratica: "gestionePratiche/setProblemaSuPratica",
                fetchNotePratica: "gestionePratiche/fetchNotePratica"
            }),
            onIndietroClicked() {
                this.$router.push({name : "Pratiche.MenuGestionePratiche"});
            },
            onRegistraClicked() {
                // Registra la segnalazione
                let numero_pratica = this.formPratica.preventivo.id;
                this.setProblemaSuPratica({numero_pratica:numero_pratica,
                                           descrizione:this.descrizione_errori,
                                           toBackOffice: false });
                this.$router.push({name : "Pratiche.MenuGestionePratiche"});
            }
        },
        async mounted() {
            this.annotazione = "";
            var numero_pratica = this.formPratica.preventivo.id;

            if (!commonLib.isEmpty(this.formPratica)) {
                this.annotazione = await this.fetchNotePratica(numero_pratica);
            }
            this.descrizione_errori = "";
        },
        async activated() {
            this.annotazione = "";
            var numero_pratica = this.formPratica.preventivo.id;

            if (!commonLib.isEmpty(this.formPratica)) {
                this.annotazione = await this.fetchNotePratica(numero_pratica);
            }
            this.descrizione_errori = "";
        }
    }
</script>
